function fitGrid($el) {
    let $bcr = $el.getBoundingClientRect();
    $el.style.width = `${ Math.ceil($bcr.width / 45) * 45 - 1}px`;
    $el.style.height = `${ Math.ceil($bcr.height / 45) * 45 - 1}px`;
}

function fitInGrid($el) {
    let $bcr = $el.getBoundingClientRect();
    $el.style.width = `${ Math.ceil(($bcr.width - 1) / 45) * 45 - 1}px`;
    $el.style.height = `${ Math.ceil(($bcr.height - 1) / 45) * 45 - 1}px`;
}

function fitGrids() {
    document.querySelectorAll('.bg-grid').forEach(($el) => {
        fitGrid($el);
    });
    document.querySelectorAll('.bg-grid > *').forEach(($el) => {
        fitInGrid($el);
    });
}

// fitGrids();

import '~/css/application-tailwind.css'
import '~/css/styles.scss'

import EmblaCarousel from 'embla-carousel'

export const setupPrevNextBtns = (prevBtn, nextBtn, embla) => {
    prevBtn.addEventListener('click', embla.scrollPrev, false);
    nextBtn.addEventListener('click', embla.scrollNext, false);
};

export const disablePrevNextBtns = (prevBtn, nextBtn, embla) => {
    return () => {
        if (embla.canScrollPrev()) prevBtn.removeAttribute('disabled');
        else prevBtn.setAttribute('disabled', 'disabled');

        if (embla.canScrollNext()) nextBtn.removeAttribute('disabled');
        else nextBtn.setAttribute('disabled', 'disabled');
    };
};

const wrap = document.querySelector(".embla");
if (wrap) {
    const viewPort = wrap.querySelector(".embla__viewport");
    const prevBtn = wrap.querySelector(".embla__button--prev");
    const nextBtn = wrap.querySelector(".embla__button--next");
    const embla = EmblaCarousel(viewPort, { align: "start", loop: true, skipSnaps: true });
    window.embla = embla;
    const disablePrevAndNextBtns = disablePrevNextBtns(prevBtn, nextBtn, embla);

    setupPrevNextBtns(prevBtn, nextBtn, embla);

    embla.on("select", disablePrevAndNextBtns);
    embla.on("init", disablePrevAndNextBtns);
    // embla.scrollTo(1);

}


// menu toggle

const menuToggle = document.querySelector('.menu-toggle');
const primaryMenu = document.querySelector('#primary-navigation');

menuToggle.addEventListener('click', () => {
    primaryMenu.hasAttribute("data-visible") ?
        menuToggle.setAttribute("aria-expanded", false) :
        menuToggle.setAttribute("aria-expanded", true);
    primaryMenu.toggleAttribute("data-visible");
});